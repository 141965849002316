<template>
	<MemberAgree v-if="!agreeTermState"></MemberAgree>
	<MemberSignup v-if="agreeTermState && !successSignUp"></MemberSignup>
	<MemberComplete v-if="successSignUp"></MemberComplete>
</template>

<script setup>
import MemberAgree from '@/components/membership/signup/MemberAgree.vue';
import MemberSignup from '@/components/membership/signup/MemberSignup.vue';
import MemberComplete from '@/components/membership/signup/MemberComplete.vue';
import { ref } from 'vue';
import { signUpStateStore } from '@/store/UserStore';
import { storeToRefs } from 'pinia';

// 회원가입 시 사용되는 상태값 스토어
const store = signUpStateStore();
const { agreeTermState, successSignUp } = storeToRefs(store);

import { onBeforeRouteLeave } from 'vue-router';

// 회원가입 페이지를 떠날 때 스토어값 초기화
onBeforeRouteLeave((to, from) => {
	store.$reset();
});
</script>

<style></style>
