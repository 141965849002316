<template>
	<div class="login_members_wrap">
		<div class="login_members">
			<div class="login_header_wrap">
				<h2>회원가입</h2>
				<p>회원가입을 하시면 더 많은 서비스를 이용할 수 있습니다.</p>
			</div>
			<div class="join_agree_wrap">
				<ul>
					<li class="w_100">
						<input
							@click="clickAllCheck()"
							v-model="all_check"
							type="checkbox"
							class="g_check"
							id="g_check1"
						/><label for="g_check1">안내 내용을 읽었으며 모두 동의합니다.</label>
					</li>
					<li>
						<input v-model="toon_info_check" type="checkbox" class="g_check" id="g_check2" /><label
							for="g_check2"
							><span>(필수)</span> 툰플릭스 이용약관 동의</label
						><a class="cursor_pointer" @click.prevent="onTermModal">[전문보기]</a>
					</li>
					<li>
						<input v-model="user_info_check" type="checkbox" class="g_check" id="g_check3" /><label
							for="g_check3"
							><span>(필수)</span> 개인정보 취급방침 동의</label
						><a class="cursor_pointer" @click.prevent="onPrivacyModal">[전문보기]</a>
					</li>
					<li>
						<input v-model="event_check" type="checkbox" class="g_check" id="g_check4" /><label
							for="g_check4"
							><span>(선택)</span> 이벤트 혜택 및 마케팅 정보 알림</label
						>
					</li>
					<li>
						<input v-model="age_check" type="checkbox" class="g_check" id="g_check5" /><label for="g_check5"
							><span>(필수)</span> 만 14세 이상입니다.</label
						>
					</li>
				</ul>
			</div>
			<div class="btn_wrap">
				<button type="button" @click="goToSignUpPage()" class="btn_basic_100 btn_green_bg">확인</button>
			</div>
			<div class="foot_servicecenter">
				<div class="call">
					<a href="">고객센터 : <span>070-8825-5004</span></a>
				</div>
				<div class="email">
					<a href="">이메일 : <span>info@ideaconcert.com</span></a>
				</div>
			</div>
		</div>
		<teleport to=".wrapper">
			<!-- <div class="modal_wrap" v-if="modalActive"> -->
			<!-- <div class="modal modal_mxc" v-if="modalActive"> -->
			<component :is="setModalComponent" v-if="modalActive"></component>
			<!-- </div> -->
			<!-- </div> -->
		</teleport>
	</div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { signUpStateStore } from '@/store/UserStore';
import { storeToRefs } from 'pinia';
import { contentModalStore } from '@/store/contentDetailStore';
import PrivacyPolicyModal from '@/components/membership/modal/PrivacyPolicyModal';
import TermOfServiceModal from '@/components/membership/modal/TermOfServiceModal';

const all_check = ref(null);
const toon_info_check = ref(null);
const user_info_check = ref(null);
const event_check = ref(null);
const age_check = ref(null);
const obj_check = ref([toon_info_check, user_info_check, event_check, age_check]);

const store = signUpStateStore();
const { agreeTermState, successSignUp } = storeToRefs(store);

const router = useRouter();

const clickAllCheck = () => {
	obj_check.value.map((p) => {
		return (p.value = !all_check.value);
	});
};

// 모달 store 세팅
const modalStore = contentModalStore();
const { modalActive, modalType } = storeToRefs(modalStore);

// 이용약관 모달 출력
const onTermModal = () => {
	modalActive.value = true;
	modalType.value = 'term';
};

// 개인정보취급방침 모달 출력
const onPrivacyModal = () => {
	modalActive.value = true;
	modalType.value = 'privacy';
};

//3가지 필수약관에 동의하는 분기
const goToSignUpPage = () => {
	if (toon_info_check.value && user_info_check.value && age_check.value) {
		agreeTermState.value = true;
	} else {
		alert('모든 필수 약관에 동의가 필요합니다.');
	}
};

watch(
	() =>
		obj_check.value.map((p) => {
			return p.value;
		}),
	() => {
		if (
			obj_check.value[0].value &&
			obj_check.value[1].value &&
			obj_check.value[2].value &&
			obj_check.value[3].value
		) {
			all_check.value = true;
		} else {
			// 하나라도 체크안했을 경우 모두 동의항목도 체크 안함
			all_check.value = false;
		}
	},
);

// 모달 컴포넌트 선택 ( Login 필요, 서비스 이용, 별점 및 댓글 등록 완료)
const setModalComponent = computed(() => {
	switch (modalType.value) {
		case 'term':
			return TermOfServiceModal;
		case 'privacy':
			return PrivacyPolicyModal;
		default:
			return false;
	}
});
</script>
