<template>
	<div class="mypage_area_wrap">
		<div class="mypage_content_wrap">
			<h2>회원가입</h2>
			<form id="sendForm">
				<div class="mypage_content_box_wrap d_flex2">
					<div class="mypage_content_box w-49pst">
						<div class="mypage_tit_wrap">
							<h4>기본정보</h4>
							<p><span class="point_star">*</span>는 필수 입력 사항입니다.</p>
						</div>

						<div class="form_box_wrap">
							<label for="name">
								<div class="form_box_tit">
									<h5>회원 아이디(이메일 주소)</h5>
									<span class="point_star">*</span>
								</div>
							</label>
							<div class="input_btn_wrap">
								<div class="item_id">
									<input
										type="text"
										name="mem_id"
										id="id"
										v-model="email"
										placeholder="이메일 주소를 입력해 주세요."
									/>
								</div>
								<div class="btn">
									<button @Click="send()" class="send_btn" type="button" value="전송">
										{{ send_btn_text }}
									</button>
								</div>
							</div>
							<p id="temp_email"></p>
							<div class="input_btn_wrap">
								<div class="item_id">
									<input type="text" class="code" value="" placeholder="인증번호를 입력해 주세요." />
									<span class="time"> {{ real_time }}</span>
								</div>
								<div class="btn">
									<button
										type="button"
										v-if="!code_btn_text"
										@click="certification()"
										class="btn_green_bg"
									>
										인증
									</button>
									<button type="button" v-else @click="certification()" class="btn_green_bg">
										완료
									</button>
								</div>
							</div>
							<p id="temp_code"></p>
							<div class="explanation">
								인증번호가 발송되지 않았거나 이메일을 잘못입력한 후 발송했을 경우
								<span class="red"
									><a class="cursor_pointer" @click="time_reset(), re_send()">재전송</a></span
								>을 클릭해 주세요.
							</div>
						</div>

						<div class="form_box_wrap">
							<label for="name">
								<div class="form_box_tit">
									<h5>비밀번호</h5>
									<span class="point_star">*</span>
								</div>
							</label>
							<input
								type="password"
								class="password"
								name="password"
								v-model="password_text"
								placeholder="새 비밀번호를 입력해주세요. "
								@keyup="pw_check()"
							/>
							<input
								type="password"
								id="password_ck"
								placeholder="새 비밀번호를 다시 입력해주세요. "
								v-model="password_ck_text"
								class="mgb0"
								@keyup="pw_second_check()"
							/>
							<p id="temp_text"></p>
							<div class="explanation">
								비밀번호는 <span class="red">8~32자의 영문+숫자+특수문자</span>조합 이어야 합니다.
							</div>
						</div>
						<div class="form_box_wrap">
							<label for="name">
								<div class="form_box_tit">
									<h5>추가 이메일주소</h5>
									<span class="point_star">*</span>
								</div>
							</label>
							<input
								type="text"
								name="sub_email"
								placeholder="아이디 변경 불가능"
								class="mgb0"
								id="assistant_email"
								v-model="second_email"
								@keyup="second_email_ck()"
							/>
							<p id="second_temp_email"></p>
							<div class="explanation">
								<span class="red">아이디(이메일) 찾기용</span>으로 사용됩니다.
							</div>
						</div>
					</div>
					<div class="mypage_content_box w-49pst">
						<div class="mypage_tit_wrap">
							<h4>추가정보</h4>
							<p><span class="point_star">*</span>는 필수 입력 사항입니다.</p>
						</div>
						<div class="form_box_wrap">
							<label for="name">
								<div class="form_box_tit">
									<h5>닉네임</h5>
								</div>
							</label>
							<input
								@keyup="nickname_ck()"
								id="nickname"
								type="text"
								name="nickname"
								placeholder=""
								class="mgb0"
								v-model="nickname_text"
							/>
							<p id="temp_nickname"></p>
							<div class="explanation">닉네임은<span class="red">10자</span>이내로 기입해주세요.</div>
						</div>
						<div class="form_box_wrap">
							<div class="form_box_tit">
								<h5>성별</h5>
								<div class="checkbox_wrap">
									<input type="radio" v-model="sex" value="W" class="g_check" id="g_check1" /><label
										for="g_check1"
										>여자</label
									>
									<input type="radio" v-model="sex" value="M" class="g_check" id="g_check2" /><label
										for="g_check2"
										>남자</label
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
			<div class="form_box_wrap bg_grey_box">
				<div class="form_box_tit">
					<h5>이벤트 혜택 및 마케팅 정보 알림</h5>
					<div class="checkbox_wrap">
						<input type="checkbox" @click="use_checkbox()" class="g_check" id="g_check3" /><label
							for="g_check3"
							>수신</label
						>
						<input type="checkbox" @click="use_checkbox()" class="g_check" id="g_check4" /><label
							for="g_check4"
							>거부</label
						>
					</div>
				</div>
			</div>
			<div class="btn_wrap_line">
				<button @click="cancelRegist" type="button" class="btn_basic btn_grey_bg">취소</button>
				<!-- <router-link to="/signup/complete"> -->
				<button @click="anything()" type="button" class="btn_basic btn_green_bg">회원가입</button>
				<!-- </router-link> -->
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { EMAIL_REGI, PW_REGI } from '../Register';
import ajax from '@/api/api-manager';
import { userInfoStore } from '@/store/UserStore';

const store = userInfoStore();
const code_ck = ref(null);
const send_btn_text = ref(null);
const time = ref(180);
const real_time = ref('03:00');
const server_cf_code = ref(null);
const code_btn_text = ref(false);
const password_valid = ref(false);
const authorized = ref(false);
const sendemail = ref(false);
const sendsecondemail = ref(false);
const router = useRouter();
const password_text = ref(null);
const password_ck_text = ref(null);
const event_alarm_Y = ref(null);
const event_alarm_N = ref(null);
const nickname_text = ref(null);
const email = ref(null);
const second_email = ref(null);
const sex = ref(null);

import { signUpStateStore } from '@/store/UserStore';
import { storeToRefs } from 'pinia';

const signUpStore = signUpStateStore();
const { agreeTermState, successSignUp } = storeToRefs(signUpStore);

onMounted(() => {
	const code_sending = document.querySelector('.send_btn');
	send_btn_text.value = code_sending.value;

	use_checkbox();
});
// 회원가입 취소
const cancelRegist = () => {
	if (confirm('회원가입을 취소하시겠습니까?')) {
		router.push('/login');
	} else {
		return false;
	}
};

//회원가입 완료 로직
const anything = () => {
	//input value들 폼데이터로 만들어서 api요청에 실어서 보내기
	var form = document.getElementById('sendForm');
	var formData = new FormData(form);
	// 생년월일 입력 안할시 0 append
	// 조건에 따라 이벤트수신동의 append
	const event_alarm = () => {
		if (event_alarm_Y.value.checked === true) {
			formData.append('event_alarm_yn', 'Y');
		} else if (event_alarm_N.value.checked === true) {
			formData.append('event_alarm_yn', 'N');
		} else {
			formData.append('event_alarm_yn', '');
		}
	};
	// 조건에 따라 성별 append

	event_alarm();

	if (password_valid.value && sendsecondemail.value && authorized.value && sendemail.value) {
		ajax(`user/signup`, formData)
			.then((res) => {
				store.user_id = email.value;
				store.nickname = nickname_text.value;
				successSignUp.value = true;
				// router.replace('/signup/complete');
			})
			.catch((err) => {});
	} else if (!sendemail.value) {
		alert('아이디를 확인해주세요');
	} else if (!authorized.value) {
		alert('인증이 되지않았습니다.');
	} else if (!password_valid.value) {
		alert('비밀번호를 확인해주세요');
	} else if (!sendsecondemail.value) {
		alert('추가 이메일을 확인해주세요');
	}
};
//메일인증코드 전송완료시 시간이 줄어들게 만듬
watch(
	() => time.value,
	() => {
		if (time.value === 0) {
			real_time.value = '00:00';
		} else if (time.value > 0) {
			real_time.value = moment(time.value * 1000).format('mm:ss');
		}
	},
);
//메일인증코드 전송중 or 완료시 버튼 텍스트 변경 및 disabled 값 주기
watch(
	() => code_ck.value,
	() => {
		const code_sending = document.querySelector('.send_btn');
		if (code_ck.value === false) {
			code_sending.disabled = true;
		}
		if (code_ck.value === false) {
			send_btn_text.value = '전송중';
		} else if (code_ck.value === true) {
			send_btn_text.value = '완료';
		}
	},
);

// 인증시간 리셋 로직
const time_reset = () => {
	time.value = 180;
};

// 이벤트수신 체크박스 둘중 하나만 선택가능하게 하는 로직
const use_checkbox = () => {
	event_alarm_Y.value = document.querySelector('#g_check3');
	event_alarm_N.value = document.querySelector('#g_check4');

	event_alarm_Y.value.addEventListener('click', () => {
		event_alarm_N.value.checked = false;
		event_alarm_Y.value.checked = true;
	});
	event_alarm_N.value.addEventListener('click', () => {
		event_alarm_N.value.checked = true;
		event_alarm_Y.value.checked = false;
	});
};
//닉네임 공백 및 10자미만 작성 로직
const nickname_ck = () => {
	const nickname_temp = document.querySelector('#temp_nickname');
	if (nickname_text.value.length > 10) {
		nickname_temp.innerHTML = '닉네임은 10자리 이내로 입력해주세요.';
	} else {
		nickname_temp.innerHTML = '';
	}
};
// 패스워드 유효성 검사
const pw_check = () => {
	const temp_text = document.querySelector('#temp_text');
	temp_text.style.color = '#ff0000';
	// 정규식 표현 사용하여 패스워드 검사 진행
	const pw_regi = PW_REGI.test(password_text.value);
	if (password_text.value.length < 8 || password_text.value.length > 32) {
		temp_text.innerHTML = '비밀번호는 8 ~ 32자리 이내로 입력해주세요.';
	} else if (password_text.value.search(/\s/) != -1) {
		temp_text.innerHTML = '비밀번호는 공백 없이 입력해주세요.';
	} else if (!pw_regi) {
		temp_text.innerHTML = '비밀번호는 영문,숫자, 특수문자를 혼합하여 입력해주세요.';
	} else {
		temp_text.innerHTML = '';
	}
};
//패스워드 더블체킹 로직
const pw_second_check = () => {
	const temp_text = document.querySelector('#temp_text');
	temp_text.style.color = '#ff0000';
	const pw_second_regi = PW_REGI.test(password_ck_text.value);
	if (password_ck_text.value.length < 8 || password_ck_text.value.length > 32) {
		temp_text.innerHTML = '비밀번호는 8 ~ 32자리 이내로 입력해주세요.';
	} else if (password_ck_text.value.search(/\s/) != -1) {
		temp_text.innerHTML = '비밀번호는 공백 없이 입력해주세요.';
	} else if (!pw_second_regi) {
		temp_text.innerHTML = '비밀번호는 영문,숫자, 특수문자를 혼합하여 입력해주세요.';
	} else {
		temp_text.innerHTML = '';
	}
	if (password_ck_text.value === '') {
		temp_text.innerHTML = '비밀번호를 입력하세요';
	} else if (password_text.value !== password_ck_text.value) {
		temp_text.innerHTML = '비밀번호가 다릅니다.';
	} else {
		temp_text.innerHTML = '비밀번호가 일치합니다.';
		temp_text.style.color = 'blue';
		password_valid.value = true;
	}
};
//이메일 인증 메일 보내기
const send = () => {
	const email_temp = document.querySelector('#temp_email');
	//유효성 검사
	const email_ck = EMAIL_REGI.test(email.value);
	// 이메일공백 or 조합 유효성검사
	if (email.value !== '' && email_ck === true) {
		email_temp.innerHTML = '';
		email_temp.style.color = '#ff0000';
		ajax(
			//중복체크  API
			`user/signupDuplicate`,
			email.value,
		).then((res) => {
			if (res.data.data == false) {
				//중복 아닐시 인증메일 전송 및 code_ck 값 변경하여 버튼상태관리
				code_ck.value = false;
				window.alert('인증코드를 메일로 보냈습니다!');
				email_temp.innerHTML = '인증코드를 발송중 입니다.';
				ajax(`user/emailAuth`, email.value).then((res) => {
					code_ck.value = true;
					server_cf_code.value = res.data.data;
					email_temp.innerHTML = '인증코드 발송이 완료되었습니다!';
					email_temp.style.color = 'blue';
					//발송후 3:00 초 시간 줄어들기
					setInterval(() => {
						time.value--;
					}, 1000);
				});
				sendemail.value = true;
			} else {
				email_temp.innerHTML = '이미 등록된 이메일 입니다.';
				code_ck.value = true;
				setTimeout(() => {
					send_btn_text.value = '전송';
				});
			}
		});
	} else {
		email_temp.innerHTML = '올바른 이메일 형식이 아닙니다.';
	}
};
//재전송 로직
const re_send = () => {
	const email_temp = document.querySelector('#temp_email');
	const email_ck = EMAIL_REGI.test(email.value);
	if (email.value !== '' && email_ck === true) {
		// code_ck.value = false;
		email_temp.innerHTML = '';
		email_temp.style.color = '#ff0000';
		ajax(
			//중복체크  API
			`user/signupDuplicate`,
			email.value,
		).then((res) => {
			if (res.data.data == false) {
				window.alert('인증코드를 메일로 보냈습니다!');
				email_temp.innerHTML = '인증코드를 발송중 입니다.';
				code_ck.value = false;
				ajax(`user/emailAuth`, email.value).then((res) => {
					const cf_code = document.querySelector('.code');
					code_ck.value = true;
					email_temp.innerHTML = '인증코드 발송이 완료되었습니다!';
					email_temp.style.color = 'blue';
					server_cf_code.value = res.data.data;

					// 인증메일 재발송 완료시 버튼 and 인풋 disable 준거 다시 풀기
					cf_code.disabled = false;
					code_btn_text.value = false;
				});
				sendemail.value = true;
			} else {
				email_temp.innerHTML = '이미 등록된 이메일 입니다.';
				code_ck.value = true;
				setTimeout(() => {
					send_btn_text.value = '전송';
				});
			}
		});
	} else {
		email_temp.innerHTML = '올바른 이메일 형식이 아닙니다.';
	}
};
//세컨 이메일 중복 검사 진행
const second_email_ck = () => {
	const second_email_ck = EMAIL_REGI.test(second_email.value);
	const second_email_temp = document.querySelector('#second_temp_email');
	second_email_temp.style.color = '#ff0000';
	if (second_email.value !== '' && second_email_ck === true) {
		second_email_temp.innerHTML = '';
		ajax('user/subEmailDupe', second_email.value).then((res) => {
			if (res.data.data === false) {
				second_email_temp.innerHTML = '이메일 중복확인이 완료되었습니다.';
				second_email_temp.style.color = 'blue';
				sendsecondemail.value = true;
			} else {
				second_email_temp.innerHTML = '이메일이 중복입니다. 다른 이메일을 입력해주세요';
			}
		});
	} else {
		second_email_temp.innerHTML = '올바른 이메일 형식이 아닙니다.';
	}
};
//인증코드 유효성검사 진행
const certification = () => {
	const cf_code = document.querySelector('.code');
	const temp_code = document.querySelector('#temp_code');
	const certify_btn = document.querySelector('.btn_green_bg');
	if (cf_code.value !== '') {
		if (time.value >= 0) {
			if (cf_code.value === server_cf_code.value) {
				//인증완료시 인풋 and 버튼 disabled 및 인증시간 0 만들기
				temp_code.innerHTML = '인증이 완료되었습니다.';
				code_btn_text.value = true;
				time.value = 0;
				certify_btn.disabled = true;
				cf_code.disabled = true;
				authorized.value = true;
			} else {
				window.alert('인증코드를 확인해주세요');
			}
		} else {
			alert('인증시간이 지났습니다. 재전송 버튼을 클릭하여 다시 진행해주세요');
		}
	} else {
		window.alert('인증코드를 확인해주세요');
	}
};
</script>

<style scope>
.signup_form_data {
	width: 100%;
}
#temp_text {
	font-size: 12px;
	color: #ff0000;
	opacity: 0.8;
	margin-top: 5px;
}
#temp_email {
	font-size: 12px;
	color: #ff0000;
	opacity: 0.8;
	margin-top: 5px;
	margin-bottom: 5px;
}
#second_temp_email {
	font-size: 12px;
	color: #ff0000;
	opacity: 0.8;
	margin-top: 5px;
	margin-bottom: 5px;
}
#temp_nickname {
	font-size: 12px;
	color: #ff0000;
	opacity: 0.8;
	margin-top: 5px;
	margin-bottom: 5px;
}
#temp_code {
	font-size: 12px;
	color: blue;
	opacity: 0.8;
	margin-top: 5px;
	margin-bottom: 5px;
}
</style>
