<template>
	<div class="login_members_wrap">
		<div class="login_members">
			<h4>
				<span>{{ store.nickname }}</span
				>님 환영합니다.
			</h4>
			<p>
				<span>{{ store.user_id }}</span
				>이메일(아이디)로 오디오북 가입이 완료되었습니다. 오디오북의 다양한 서비스를 편리하게 이용해보세요!
			</p>
			<div class="btn_wrap">
				<button @click="router.replace('/login')" type="button" class="btn_basic_100 btn_green_bg">확인</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { userInfoStore } from '@/store/UserStore';
const store = userInfoStore();
const router = useRouter();
</script>
